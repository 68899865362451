import React from 'react';
import './Home.css';
import siteConfig from '../siteConfig';  // 修改这一行

function Home({ content }) {
  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <h1>{content.headline}</h1>
          <p>{content.description}</p>
          <button className="cta-button">Learn More</button>
        </div>
      </section>
      <section className="features">
        <h2>Our Advantages</h2>
        <div className="feature-grid">
          {content.features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="stats">
        <div className="stat-item">
          <h3>500+</h3>
          <p>Trusted Clients</p>
        </div>
        <div className="stat-item">
          <h3>50+</h3>
          <p>Countries Covered</p>
        </div>
        <div className="stat-item">
          <h3>24/7</h3>
          <p>Support</p>
        </div>
      </section>
      <section className="testimonial">
        <blockquote>
          "{siteConfig.company.name}'s solutions have completely transformed the way we operate our business. Their innovative technology and excellent service have kept us at the forefront of our industry."
        </blockquote>
        <p className="testimonial-author">- CEO of a Well-known Enterprise</p>
      </section>
    </div>
  );
}

export default Home;
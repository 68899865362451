import React, { useState, useRef } from 'react';
import './Contact.css';
import siteConfig from '../siteConfig'; // 导入 siteConfig

function Contact({ content }) {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    message: ''
  });

  const formRefs = {
    name: useRef(),
    subject: useRef(),
    message: useRef()
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    e.target.setCustomValidity('');
  };

  const validateForm = () => {
    let isValid = true;
    Object.keys(formData).forEach(key => {
      const element = formRefs[key].current;
      if (!element.value) {
        element.setCustomValidity(`Please enter your ${key}`);
        isValid = false;
      } else if (key === 'email' && !/\S+@\S+\.\S+/.test(element.value)) {
        element.setCustomValidity('Please enter a valid email address');
        isValid = false;
      } else {
        element.setCustomValidity('');
      }
      element.reportValidity();
    });
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const { name, subject, message } = formData;
      const recipientEmail = siteConfig.company.recipientEmail; // 使用配置的收件人邮箱
      const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\n\n${message}`)}`;
      window.location.href = mailtoLink;
    }
  };

  return (
    <div className="contact">
      <h1>{content.headline}</h1>
      <p className="contact-intro">{content.description}</p>
      <div className="contact-content">
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p><strong>Address:</strong> {content.address}</p>
          <p><strong>Email:</strong> {content.email}</p>
          <p><strong>Phone:</strong> {content.phone}</p>
          <div className="social-links">
            {content.socialLinks.map((link, index) => (
              <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <h2>Send Us a Message</h2>
          {content.form.fields.filter(field => field.id !== 'email').map(field => (
            <div key={field.id} className="form-field">
              <label htmlFor={field.id}>{field.label}</label>
              {field.type === 'textarea' ? (
                <textarea
                  id={field.id}
                  ref={formRefs[field.id]}
                  required={field.required}
                  onChange={handleChange}
                  value={formData[field.id] || ''}
                />
              ) : (
                <input
                  type={field.type}
                  id={field.id}
                  ref={formRefs[field.id]}
                  required={field.required}
                  onChange={handleChange}
                  value={formData[field.id] || ''}
                />
              )}
            </div>
          ))}
          <button type="submit">{content.form.submitText}</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
const siteConfig = {
  title: "Pilotage Technology",
  description: "",
  company: {
    name: "Pilotage Technology",
    location: "173 Union St, Dunstable, United Kingdom, LU6 1HA",
    email: "service@pilotage.life",
    logoUrl: "/images/logo.png",
    recipientEmail: "contact@pilotage.life",
  },
  pages: [
    {
      id: "home",
      title: "Home",
      path: "/",
      content: {
        headline: "Welcome to Pilotage Technology",
        description:
          "We are a cutting-edge technology company focused on providing top-notch solutions to clients worldwide. Our vision is to revolutionize the industry with innovative and sustainable products.",
        features: [
          {
            title: "User-Friendly Interface",
            description:
              "Intuitive interface design ensuring a seamless experience for users.",
          },
          {
            title: "Global Compatibility",
            description:
              "Supports multiple languages and regions, adaptable to a wide range of devices.",
          },
          {
            title: "Real-Time Updates",
            description:
              "Regular updates ensure continuous optimization of app performance.",
          },
        ],
      },
    },
    {
      id: "about",
      title: "About Us",
      path: "/about",
      content: {
        headline: "Who We Are",
        description: "Pilotage Technology was founded in 2023 with a vision to revolutionize the tech industry. Our team of passionate innovators is dedicated to creating cutting-edge solutions that empower businesses worldwide.",
        values: [
          {
            icon: "🚀",
            title: "Innovation",
            description: "We constantly push the boundaries of what's possible in technology."
          },
          {
            icon: "🤝",
            title: "Collaboration",
            description: "We believe in the power of teamwork and partnership."
          },
          {
            icon: "🌍",
            title: "Global Impact",
            description: "Our solutions are designed to make a positive difference worldwide."
          },
          {
            icon: "🔒",
            title: "Integrity",
            description: "We uphold the highest standards of ethics and transparency."
          }
        ],
        team: [
          {
            name: "John Doe",
            role: "CEO & Founder",
            photo: "/images/team/john.jpg",
            bio: "John has over 10 years of experience in the tech industry and is passionate about driving innovation.",
          },
          {
            name: "Jane Smith",
            role: "CTO",
            photo: "/images/team/jane.jpg",
            bio: "Jane is a seasoned engineer with expertise in cloud computing and AI.",
          },
          // Add more team members as needed
        ],
      },
    },
    {
      id: "products",
      title: "Products & Services",
      path: "/products",
      content: {
        headline: "Our Innovative Products",
        intro: "At Pilotage Technology, we offer a range of cutting-edge products and services designed to transform your business. Our solutions leverage the latest in AI and cloud technologies to deliver unparalleled performance and insights.",
        products: [
          {
            title: "SpaceSaver Pro",
            description: "Relying on powerful cloud computing and AI analysis capabilities, we can automatically identify various junk files located in the phone with one-click cleaning, and clean them up in time to get more storage space for users.",
            features: [
              { icon: "📊", text: "Real-time analysis of junk files" },
              { icon: "☝️", text: "One-click cleanup" },
              { icon: "📱", text: "Exquisite UI" },
              { icon: "🔒", text: "Enterprise-grade security" }
            ],
            imageGallery: [
              "/images/product/feature_1.png",
              "/images/product/feature_2.png",
              "/images/product/feature_3.png",
            ],
          },
          // Add more products as needed
        ],
      },
    },
    {
      id: "contact",
      title: "Contact Us",
      path: "/contact",
      content: {
        headline: "Get in Touch",
        description: "We'd love to hear from you! Whether you have a question about our products, need technical support, or want to explore partnership opportunities, our team is ready to assist you.",
        address: "123 Tech Street, Innovation City, 12345",
        email: "service@pilotage.life",
        phone: "+66964219500",
        socialLinks: [
          { icon: "📘", url: "https://facebook.com" },
          { icon: "🐦", url: "https://twitter.com" },
          { icon: "📸", url: "https://instagram.com" },
          { icon: "💼", url: "https://linkedin.com" }
        ],
        form: {
          fields: [
            { id: "name", label: "Name", type: "text", required: true },
            { id: "email", label: "Email", type: "email", required: true },
            { id: "subject", label: "Subject", type: "text", required: true },
            { id: "message", label: "Message", type: "textarea", required: true },
          ],
          submitText: "Send Message",
        },
      },
    },
  ],
};

module.exports = siteConfig;
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header({ company, pages }) {
  return (
    <header className="main-header">
      <div className="logo">
        <Link to="/">
          {company.name}
        </Link>
      </div>
      <nav>
        <ul>
          {pages.map(page => (
            <li key={page.id}>
              <Link to={page.path}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
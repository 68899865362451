import React from 'react';
import './Products.css';

function Products({ content }) {
  return (
    <div className="products">
      <h1>{content.headline}</h1>
      {content.products.map((product, index) => (
        <section key={index} className="product">
          <h2>{product.title}</h2>
          <p>{product.description}</p>
          <div className="product-features">
            {product.features.map((feature, idx) => (
              <div key={idx} className="feature">
                <span className="feature-icon">{feature.icon}</span>
                <span>{feature.text}</span>
              </div>
            ))}
          </div>
          <div className="image-gallery">
            {product.imageGallery.map((image, idx) => (
              <img key={idx} src={image} alt={`${product.title} ${idx + 1}`} />
            ))}
          </div>
        </section>
      ))}
    </div>
  );
}

export default Products;
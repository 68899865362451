import React from 'react';
import './About.css';

function About({ content }) {
  return (
    <div className="about">
      <section className="about-hero">
        <h1>{content.headline}</h1>
        <p>{content.description}</p>
      </section>
      <section className="company-values">
        <h2>Our Values</h2>
        <div className="values-grid">
          {content.values.map((value, index) => (
            <div key={index} className="value-card">
              <div className="value-icon">{value.icon}</div>
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="team">
        <h2>Our Team</h2>
        <div className="team-grid">
          {content.team.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.photo} alt={member.name} />
              <h3>{member.name}</h3>
              <p className="member-role">{member.role}</p>
              <p className="member-bio">{member.bio}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default About;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
const siteConfig = require('./siteConfig');

// 设置文档标题
document.title = siteConfig.title;

// 设置描述 meta 标签
const metaDescription = document.createElement('meta');
metaDescription.name = 'description';
metaDescription.content = siteConfig.description;
document.head.appendChild(metaDescription);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Contact from './pages/Contact';
import siteConfig from './siteConfig';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header company={siteConfig.company} pages={siteConfig.pages} />
        <main>
          <Routes>
            {siteConfig.pages.map(page => (
              <Route 
                key={page.id} 
                path={page.path} 
                element={
                  page.id === 'home' ? <Home content={page.content} /> :
                  page.id === 'about' ? <About content={page.content} /> :
                  page.id === 'products' ? <Products content={page.content} /> :
                  page.id === 'contact' ? <Contact content={page.content} /> :
                  null
                } 
              />
            ))}
          </Routes>
        </main>
        <Footer company={siteConfig.company} />
      </div>
    </Router>
  );
}

export default App;
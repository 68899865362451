import React from 'react';
import './Footer.css';

function Footer({ company }) {
  return (
    <footer className="main-footer">
      <div className="footer-content">
        <p>&copy; 2024 {company.name}. All rights reserved.</p>
        <p>{company.location}</p>
        <p>Email: {company.email}</p>
      </div>
    </footer>
  );
}

export default Footer;